const Nav = require('bluegg-nav');

var mainNav = new Nav({
	nav: '#site-nav',
	trigger: '#primary-nav-trigger'
});

var urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('error')) {
	let errorbox = document.querySelector('#errorbox');
	let errorboxmsg = errorbox.querySelector('#errorboxmsg');
	errorboxmsg.innerHTML = urlParams.get('error');
	errorbox.classList.add('is-open');
}

let contactForm = document.querySelector('#contactform');

if (contactForm) {
	let contactFormSubmit = contactForm.querySelector('button[type="submit"]');
	contactFormSubmit.addEventListener('click', function(e) {
		contactForm.classList.add('submitted');
	});
}


// Subscribe AJAX

var forms = document.querySelectorAll('[data-mc-form]');
forms.forEach(function(form) {
  form.addEventListener('submit', function(e) {
    e.preventDefault();

    var form = e.target;

    var errorMsg        = form.querySelector('[data-error-msg');
    var successMsg      = form.querySelector('[data-success-msg');
    var listId          = form.querySelector('[data-list-id]');
		var fieldsContainer = form.querySelector('[data-fields]');
    var email           = form.querySelector('[data-email-field]');
    var name            = form.querySelector('[data-name-field]');
    var agree           = form.querySelector('[data-agree-field]');

		console.log(listId.value);


    if (email.value == '' || agree.checked == false) {
      errorMsg.classList.add('email-signup__error-container--visible')
      return false;
    }

    var xhr = new XMLHttpRequest();

    xhr.open('POST', '/prod/api/subscribe');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onload = function()
    {
      var json = JSON.parse(xhr.responseText);

      if (xhr.status === 200 && json.result == true ) {
        errorMsg.classList.remove('email-signup__error-container--visible');
        successMsg.classList.add('email-signup__success-container--visible');
        fieldsContainer.classList.add('hidden');
      }
      else if (json.error !== '') {
        alert('Something went wrong');
      }
    };
    console.log(xhr.status);
    xhr.send(encodeURI('email=' + email.value + '&name=' + name.value + '&listId=' + listId.value));
  });
});

